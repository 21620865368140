import clsx from 'clsx'
import qs from 'query-string'

import NaverLogo from '@/assets/svgs/naver_logo.svg'
import KakaoLogo from '@/assets/svgs/kakao_logo.svg'

import HomeHeader from '@/components/Header/Home'
import { colors } from '@/styles/theme'
import { useRouter } from 'next/router'
import { withAuthGetServerSideProps } from '@/utils/withAuth'
import useAuth, { PropsWithAuth } from '@/hooks/useAuth'
import BaseLayout from '@/components/Layout'

const OAUTH_BUTTON_CONFIG = [
  'flex',
  'h-[66px]',
  'items-center',
  'font-Pretendard',
  'text-[17px]',
  'font-[500]',
]

const naverOauthUrl = `https://nid.naver.com/oauth2.0/authorize?${qs.stringify({
  response_type: 'code',
  client_id: process.env.NEXT_PUBLIC_NAVER_CLIENT_ID,
  redirect_url: process.env.NEXT_PUBLIC_NAVER_REDIRECT_URI,
  state: process.env.NEXT_PUBLIC_NAVER_STATE,
})}`
const kakaoOauthUrl = `https://kauth.kakao.com/oauth/authorize?${qs.stringify({
  response_type: 'code',
  client_id: process.env.NEXT_PUBLIC_KAKAO_REST_KEY,
  redirect_uri: process.env.NEXT_PUBLIC_KAKAO_REDIRECT_URI,
})}`

export default function Login({ auth }: PropsWithAuth) {
  useAuth(auth, { signedAccessRequired: false, loginIfNotSigned: false })
  const router = useRouter()

  return (
    <BaseLayout>
      <div className={clsx('flex', 'pt-[70px]', 'px-[16px]', 'pb-[50px]')}>
        <HomeHeader menuButtonVisible={false} />
        <div className={clsx('flex-1', 'justify-end', 'flex', 'flex-col')}>
          {/* <button
            className={clsx(
              OAUTH_BUTTON_CONFIG,
              'bg-[#03C75A]',
              'text-white',
              'w-full',
            )}
            onClick={() => router.push(naverOauthUrl)}
          >
            <span className="w-[75px] flex items-center justify-center">
              <NaverLogo fill={colors.white} width={23} height={23} />
            </span>
            네이버로 시작하기
          </button> */}
          <button
            className={clsx(
              OAUTH_BUTTON_CONFIG,
              'bg-[#FEE500]',
              'text-[#391B1B]',
              'w-full',
              'my-[100px]',
            )}
            onClick={() => router.push(kakaoOauthUrl)}
          >
            <span className="w-[75px] flex items-center justify-center">
              <KakaoLogo fill={'#391B1B'} width={23} height={23} />
            </span>
            카카오로 시작하기
          </button>
        </div>
      </div>
    </BaseLayout>
  )
}

export const getServerSideProps = withAuthGetServerSideProps()
